.ap-logo {
  object-fit: contain;
}

.ap-logo__link,
.ap-logo__link:link,
.ap-logo__link:hover,
.ap-logo__link:active,
.ap-logo__link:visited {
  color: var(--standalone-form__logo-color);
}

.ap-logo__img {
  width: 8rem;
}
