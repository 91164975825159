.send-receive-confirm-header {
  display: flex;
  align-items: center;
}

.send-receive-confirm-header__icon {
  width: 3.6rem;
  height: 3.6rem;
  color: var(--font__color-secondary);
}

.send-receive-confirm-header__title {
  font-size: 2rem;
  padding-left: 1.6rem;
}

.send-receive-confirm-modal__header {
  padding: 1.8rem;
}
