.price-chart {
  /* 
    100% - (.trading-layout__chart-header) - (depth chart)
  */
  height: calc(100% - var(--component__header-height) - 200px);
}

@media only screen and (max-height: 1080px) {
  .price-chart {
    height: 100%;
  }
}

@media only screen and (max-width: 968px) and (min-height: 1080px) {
  .price-chart {
    height: 100%;
  }
}

.price-chart__chart {
  height: 100%; 
}

.price-chart__chart #apex-chart-container .no-chart-data {
  position: absolute;
  z-index: var(--z-index__price-chart);
  margin-left: 1.2rem;
}

.price-chart__header {
  height: var(--component__header-height);
  line-height: var(--component__header-height);
  background: var(--component__header-bg-color);
  padding: 0 1.75rem;
  border-bottom: 1px solid var(--component__header-separator-color);
}

#apex-chart-container {
  font-family: var(--font__typeface);
}

#apex-chart-container .charts .chart.order-book-depth,
#apex-chart-container .charts .chart.macd-history {
  visibility: hidden;
  z-index: var(--z-index__price-chart);
}
#apex-chart-container .controls {
  padding: 0 0.5rem;
  height: var(--component__sub-header-height);
  border-bottom: solid 1px var(--table__row-separator-color);
}
#apex-chart-container .charts .chart.price-history {
  bottom: 30%;
  border-bottom: solid 1px var(--chart__row-separator-color);
}
#apex-chart-container .charts .chart.volume-history {
  top: 70%;
  bottom: 15%;
  border-bottom: solid 2px var(--chart__row-separator-color);
}
#apex-chart-container .charts .chart.range-selector {
  top: 85%;
  bottom: 0%;
  border-bottom: 0;
}
#apex-chart-container .charts .chart.range-selector .range {
  background: var(--chart__range-selector-bg-color);
  border-left: 1px solid var(--chart__range-selector-handles-color);
  border-right: 1px solid var(--chart__range-selector-handles-color);
  height: 100% !important;
}
#apex-chart-container .charts .chart.range-selector .handle {
  background: var(--chart__range-selector-handles-color);
  top: 30% !important;
}

#apex-chart-container .controls {
  justify-content: flex-start;
}
#apex-chart-container .controls .select-group button {
  border-radius: 2px;
  border: solid 1px var(--component__border-color);
  padding: 0.5rem;
  color: var(--component__color-primary);
  background: var(--tertiary__bg-color);
  letter-spacing: 0.5px;
  text-align: center;
}
#apex-chart-container .controls .button-group {
  font-family: var(--font__typeface);
}
#apex-chart-container .controls .button-group button {
  width: 4rem;
  height: 2.4rem;
  padding: 0;
  font-size: 1.1rem;
  background-color: var(--tertiary__bg-color);
  color: var(--component__color-primary);
  border: solid 1px var(--component__border-color);
}
#apex-chart-container .controls .button-group button.active {
  background-color: var(--secondary__bg-color);
  color: var(--component__color-primary);
  border: solid 1px var(--component__border-color);
}
#apex-chart-container .controls .select-group button {
  width: 5.7rem;
  height: 2.4rem;
  font-size: 1.1rem;
}
#apex-chart-container .controls .select-group ul {
  background-color: var(--tertiary__bg-color);
  margin: 0;
  padding: 0 0 0.5rem;
  border: solid 1px var(--component__border-color);
}
#apex-chart-container .controls .select-group ul li {
  padding: 0.5rem;
  list-style: none;
  margin: 0;
  color: var(--component__color-primary);
}
#apex-chart-container .controls .select-group ul li.active {
  background-color: var(--secondary__bg-color);
  color: var(--component__color-primary);
}
#apex-chart-container .charts .chart.price-history .info {
  justify-content: space-between;
  font-family: var(--font__typeface);
  font-size: 10px;
  color: var(--chart__info-color);
  max-width: 168rem;
}
#apex-chart-container .charts .chart.price-history .info span {
  flex: none;
  font-size: 1.2rem;
}
#apex-chart-container .charts .chart.price-history .info label {
  color: var(--chart__info-label-color);
  font-size: 1.2rem;
}
