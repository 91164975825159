.fiat-sidepane {
  display: flex;
  height: 100%;
  border-top: 4px solid var(--tab__border-color--selected);
}

.fiat-sidepane__container {
  max-width: initial;
  width: 88.8rem;
}

.fiat-sidepane__main {
  display: flex;
  flex-direction: column;
  width: 54.5rem;
  padding: 3.2rem;
  border-right: 1px solid var(--border__color);
  overflow: scroll;
}

.fiat-sidepane__main-header {
  height: 13.4rem;
}

.fiat-sidepane__main-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  box-shadow: var(--component__box-shadow);
  border: solid 1px rgba(0, 0, 0, 0.06);
  background-color: var(--wallet-side-pane__bg-color);
}

.fiat-sidepane__side {
  width: 34.3rem;
}

.fiat-sidepane__address-header {
  margin: 5.6rem 0 0 2.5rem;
  padding-bottom: 1.1rem;
  font-size: 1.4rem;
  letter-spacing: -0.5px;
  color: var(--font__color-primary);
  border-bottom: 1px solid var(--border__color);
}

.fiat-sidepane__address-instructions {
  padding: 1rem 2.5rem 2.5rem;
  font-size: 1.2rem;
}

.fiat-sidepane__side-details {
  margin-top: 12.5rem;
}

.fiat-sidepane__send-side-details {
  margin-top: 27rem;
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .fiat-sidepane {
    display: block;
    overflow-y: auto;
  }
  .fiat-sidepane__main {
    width: auto;
  }
  .fiat-sidepane__side-details {
    margin-top: 0;
  }
}

/* MEDIA QUERIES */

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .fiat-sidepane__side {
    width: calc(100% - 12rem);
  }
  .fiat-sidepane__side > .sidepane-details__divider,
  .fiat-sidepane__side > .sidepane-details__body {
    width: unset;
  }
}
