.ap-popover__popover {
  position: relative;
}

.ap-popover__content {
  z-index: var(--z-index__popover);
  position: absolute;
  right: 0;
  padding-top: 0.8rem;
  margin-top: -0.8rem;
}

.ap-popover__content > div {
  box-shadow: var(--component__box-shadow);
  border-radius: 0 0 4px 4px;
}

.ap-popover__visible {
  display: block;
}

.ap-popover__hidden {
  display: none;
}

.ap-popover__content::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
  right: 1.1rem;
  border-left: 0.8rem solid transparent;
  border-right: 0.8rem solid transparent;
  border-bottom: 0.8rem solid var(--popover__tooltip-color);
}

.instrument-selector-popover__content::after {
  border-bottom: 0.8rem solid var(--popover__content-border-color);
}

/* MEDIA QUERIES */

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
}
@media only screen and (max-width: 992px) {
  .ap-popover__content::after {
    right: 2rem;
  }
}
