.wallet-nav-item {
  margin-left: 1.2rem;
  padding: 0 1rem;
  background: var(--wallet-card__button-bg-color);
  border: 1px solid var(--wallet-card__button-border-color);
}

.wallet-nav-item__label {
  text-transform: capitalize;
  padding-left: 1rem;
}

.wallet-nav-item--disabled {
  opacity: 0.5;
}