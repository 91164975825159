.fiat-tabs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  min-height: 5.2rem;
  height: 5.2rem;
}

.fiat-tabs__tab {
  font-size: 1.4rem;
  height: 5rem;
  line-height: 5rem;
  flex: 1 1 auto;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid var(--border__color);
  color: var(--tab__font-color);
  justify-content: center;
  align-items: center;
}

.fiat-tabs__tab--disabled {
  opacity: 0.3;
}

.fiat-tabs__tab:first-child {
  border-right: 1px solid var(--border__color);
}

.fiat-tabs__tab-icon {
  margin-right: 0.8rem;
  width: 1.7rem;
  height: 2.5rem;
  margin-top: -2.2rem;
}

.fiat-tabs__tab--selected {
  color: var(--tab__font-color--selected);
  border-bottom: 2px solid var(--tab__border-color--selected);
}

.fiat-tabs__tab-text {
  position: relative;
  top: -0.4rem;
}
