.bulk-cancel-buttons {
  height: 3.5rem;
  padding: 0 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--bulk-cancel-btn__bg-color);
  border-top: solid 1px var(--balk-cancel-btn__border-color);
}

.bulk-cancel-buttons__label {
  margin-right: 0.75rem;
  color: var(--bulk-cancel-btn__label-color);
}

.bulk-cancel-buttons__btn {
  width: 6rem;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  margin-left: 0.75rem;
  height: 2rem;
}


.bulk-cancel-buttons__image {
  margin: 0;
  margin-right: 2px;
  width: 16px;
  height: 16px;
}
